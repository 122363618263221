import React, { useState } from "react";
import "./CrusherRentals.css";

function CrusherRentals() {
  const [showQuoteModal, setShowQuoteModal] = useState(false);

  const handleShow = () => setShowQuoteModal(true);
  const handleClose = () => setShowQuoteModal(false);

  // Handle WhatsApp Click
  const handleWhatsApp = (event) => {
    event.preventDefault();

    const form = event.target.closest("form"); // Get the form element
    const formData = {
      name: form.elements["name"].value,
      email: form.elements["email"].value,
      phone: form.elements["phone"].value,
      message: form.elements["message"].value,
    };

    // WhatsApp Message Format
    const whatsappMessage = `New quote request!%0A%0A🔹 Name: ${formData.name}%0A🔹 Email: ${formData.email}%0A🔹 Phone: ${formData.phone}%0A🔹 Message: ${formData.message}`;
    
    const whatsappLink = `https://wa.me/+96892681010?text=${whatsappMessage}`;
    window.open(whatsappLink, "_blank");
  };

  // Handle Email Click
  const handleEmail = (event) => {
    event.preventDefault();

    const form = event.target.closest("form"); // Get the form element
    const formData = {
      name: form.elements["name"].value,
      email: form.elements["email"].value,
      phone: form.elements["phone"].value,
      message: form.elements["message"].value,
    };

    // Email Format
    const subject = "New Quote Request";
    const body = `Name: ${formData.name}%0AEmail: ${formData.email}%0APhone: ${formData.phone}%0AMessage: ${formData.message}`;
    const mailtoLink = `mailto:sales@nizwaeng.com?subject=${subject}&body=${body}`;

    window.location.href = mailtoLink; // Opens the default email client
  };

  return (
    <div className="crusher-container">
      <h1 className="crusher-title">Your Complete Crushing & Screening Solutions</h1>
      <p className="crusher-description">
        Optimize your aggregate production with our comprehensive range of crushing and screening services.
        We provide flexible and cost-effective solutions tailored to your specific project needs.
      </p>

      <h3>Our Services Include:</h3>
      <ul>
        <li><strong>Mobile Crusher Rentals:</strong> On-site aggregate processing with portable crushing solutions.</li>
        <li><strong>Operating Contracts for Existing Crushers:</strong> Expert operators and maintenance for your plant.</li>
        <li><strong>Sales of Used/New Stationary Crushers:</strong> High-quality crushing systems for long-term production goals.</li>
      </ul>

      <p className="crusher-description">
        Contact us today to discuss your crushing and screening requirements.
      </p>

      <div className="quote-button-container">
        <button className="quote-button" onClick={handleShow}>
          Get a Quote
        </button>
      </div>

      {showQuoteModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={handleClose}>&times;</span>
            <h2>Get a Quote</h2>
            <form>
              <label htmlFor="name">Name</label>
              <input type="text" id="name" placeholder="Enter your name" required />

              <label htmlFor="email">Email Address</label>
              <input type="email" id="email" placeholder="Enter email" required />

              <label htmlFor="phone">Phone Number</label>
              <input type="tel" id="phone" placeholder="Enter phone number" required />

              <label htmlFor="message">Message</label>
              <textarea id="message" rows="3" placeholder="Enter your message" required></textarea>

              <div className="button-group">
                <button type="button" onClick={handleWhatsApp} className="whatsapp-button">
                  Send via WhatsApp
                </button>
                <button type="button" onClick={handleEmail} className="email-button">
                  Send via Email
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default CrusherRentals;
