import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './navebar';
import Home from './pages/Home';
import Services from './pages/Services';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';
import BlockDelivery from '../components/pages/BlockDelivery';
import CrusherRentals from './pages/CrusherRentals';
import EquipmentTransportation from './pages/EquipmentTransportation';
import HeavyEquipmentRentals from './pages/HeavyEquipmentRentals';
import RCPServices from './pages/RCPServices';
import { AKHIL } from '../Contacts/Contactindex';
import { Rinu } from '../Contacts/Contactindex';
import { Haitham } from '../Contacts/Contactindex';
import { SATHISH } from '../Contacts/Contactindex';
import { ISSA } from '../Contacts/Contactindex';
import { JAIFAR } from '../Contacts/Contactindex';
import { MASI } from '../Contacts/Contactindex';
import { JULANDA } from '../Contacts/Contactindex';
import Footer from './Footer';
import '../App.css';

function AppWrapper() {
  const location = useLocation();

  // Check if the current path starts with "/contactindex/"
  const isContactPage = location.pathname.startsWith('/contactindex/');

  return (
    <div>
      {/* Hide Navbar on contact pages */}
      {!isContactPage && <Navbar />}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/concrete-products" element={<BlockDelivery />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />

        {/* Service Detail Routes */}
        <Route path="/services/crusher-rentals" element={<CrusherRentals />} />
        <Route path="/services/cement-bulker" element={<HeavyEquipmentRentals />} />
        <Route path="/services/equipment-transportation" element={<EquipmentTransportation />} />
        <Route path="/services/rcp-services" element={<RCPServices />} />

        {/* Contact Pages */}
        <Route path="/contactindex/Akhil" element={<AKHIL />} />
        <Route path="/contactindex/Rinu" element={<Rinu />} />
        <Route path="/contactindex/Haitham" element={<Haitham />} />
        <Route path="/contactindex/SATHISH" element={<SATHISH />} />
        <Route path="/contactindex/ISSA" element={<ISSA />} />
        <Route path="/contactindex/JAIFAR" element={<JAIFAR />} />
        <Route path="/contactindex/MASI" element={<MASI />} />
        <Route path="/contactindex/Julanda" element={<JULANDA />} />
      </Routes>

      {/* Hide Footer on contact pages */}
      {!isContactPage && <Footer />}
    </div>
  );
}

export default AppWrapper;
