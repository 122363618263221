import React, { useState } from "react";
import './HeavyEquipmentRentals.css';

function HeavyEquipmentRentals() {
  const [showQuoteModal, setShowQuoteModal] = useState(false);

  const handleShow = () => setShowQuoteModal(true);
  const handleClose = () => setShowQuoteModal(false);

  const handleWhatsApp = (event) => {
    event.preventDefault();
    
    const name = document.getElementById("name").value.trim();
    const email = document.getElementById("email").value.trim();
    const phone = document.getElementById("phone").value.trim();
    const message = document.getElementById("message").value.trim();

    if (!name || !email || !phone || !message) {
      alert("Please fill in all fields before sending.");
      return;
    }

    const whatsappMessage = `🚀 New Quote Request:%0A%0A🔹 *Name:* ${name}%0A🔹 *Email:* ${email}%0A🔹 *Phone:* ${phone}%0A🔹 *Message:* ${message}`;
    const whatsappLink = `https://wa.me/+96892681010?text=${whatsappMessage}`;
    
    window.open(whatsappLink, "_blank");
  };

  const handleEmail = (event) => {
    event.preventDefault();

    const name = document.getElementById("name").value.trim();
    const email = document.getElementById("email").value.trim();
    const phone = document.getElementById("phone").value.trim();
    const message = document.getElementById("message").value.trim();

    if (!name || !email || !phone || !message) {
      alert("Please fill in all fields before sending.");
      return;
    }

    const subject = "New Quote Request";
    const body = `Name: ${name}%0AEmail: ${email}%0APhone: ${phone}%0AMessage: ${message}`;
    const mailtoLink = `mailto:sales@nizwaeng.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    
    window.location.href = mailtoLink;
  };

  return (
    <div className="equipment-container">
      <h1 className="equipment-title">Your Reliable Heavy Equipment Rental Partner</h1>
      <p className="equipment-description">
        Power your construction, mining, and earthmoving projects with our extensive fleet of high-performance heavy equipment.
        We offer flexible rental solutions designed to meet your project's specific demands, ensuring efficiency and productivity.
      </p>

      <h2 className="equipment-subtitle">Our Rental Services Include:</h2>
      <ul className="equipment-list">
        <li><strong>Excavator Rentals:</strong> Tackle digging, trenching, and material handling tasks with our versatile range of excavators.</li>
        <li><strong>Dozer Rentals:</strong> Achieve precise grading, leveling, and earthmoving with our powerful and reliable dozers.</li>
        <li><strong>Dumper Rentals:</strong> Efficiently transport materials across your worksite with our robust and high-capacity dumpers.</li>
        <li><strong>Wheel Loader Rentals:</strong> Streamline material handling and loading operations with our agile and powerful wheel loaders.</li>
      </ul>

      <p className="equipment-footer">
        We provide well-maintained equipment, prompt delivery, and expert support to ensure your project stays on schedule and within budget.
        Partner with us for reliable heavy equipment rentals that deliver exceptional performance.
      </p>
      
      <div className="quote-button-container">
        <button className="quote-button" onClick={handleShow}>
          Get a Quote
        </button>
      </div>

      {showQuoteModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={handleClose}>&times;</span>
            <h2>Get a Quote</h2>
            <form>
              <label htmlFor="name">Name</label>
              <input type="text" id="name" placeholder="Enter your name" required />
              
              <label htmlFor="email">Email Address</label>
              <input type="email" id="email" placeholder="Enter email" required />
              
              <label htmlFor="phone">Phone Number</label>
              <input type="tel" id="phone" placeholder="Enter phone number" required />
              
              <label htmlFor="message">Message</label>
              <textarea id="message" rows="3" placeholder="Enter your message" required></textarea>
              
              <div className="button-group">
                <button type="button" onClick={handleWhatsApp} className="whatsapp-button">
                  Send via WhatsApp
                </button>
                <button type="button" onClick={handleEmail} className="email-button">
                  Send via Email
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default HeavyEquipmentRentals;
