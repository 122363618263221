import React from 'react';
import './Home.css';
function Home() {
  return (
    <div className="home-container">
      {/* Background Section */}
      <div className="background-effect">
        <div className="center-title">
          <h1 className="title-text">Driving Oman’s Industrial Future Since 1981</h1>
          <p className="subtitle-text">Real Estate | Construction | Heavy Equipment Rental | Transportation</p>
        </div>
      </div>
      {/* Head Office Services Section */}
      <div className="services-section">
        <h3>Head Office Services</h3>
        <ul className="services-list">
          <li>Crushing & Screening </li>
          <li>Heavy Equipment Rentals</li>
          <li>Transport & Logistics Solutions</li>
        </ul>
      </div>

      {/* Al Rumeli Cement Products Section */}
      <div className="alrumeli-section1">
        <h3>Branch Services - Al Rumeli Cement Products</h3>
        <p>
          Dedicated to producing and supplying high-quality concrete products for Oman’s construction needs.
        </p>
        <ul className="services-list">
          <li>Supply of Concrete Products </li>
        </ul>
        </div>
        <div className="video-box">
          <iframe
            src="https://www.youtube.com/embed/8sXpvE0Edqs?start=1"
            title="Al Rumeli Cement Products Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      
    </div>
  );
}

export default Home;
