import React, { useState } from "react";
import './EquipmentTransportation.css';

function EquipmentTransportation() {
  const [showQuoteModal, setShowQuoteModal] = useState(false);

  const handleShow = () => setShowQuoteModal(true);
  const handleClose = () => setShowQuoteModal(false);

  // Handle WhatsApp Click
  const handleWhatsApp = (event) => {
    event.preventDefault();

    const name = document.getElementById("name").value.trim();
    const email = document.getElementById("email").value.trim();
    const phone = document.getElementById("phone").value.trim();
    const message = document.getElementById("message").value.trim();

    if (!name || !email || !phone || !message) {
      alert("Please fill in all fields before sending.");
      return;
    }

    const whatsappMessage = `🚀 New Quote Request:%0A%0A🔹 *Name:* ${name}%0A🔹 *Email:* ${email}%0A🔹 *Phone:* ${phone}%0A🔹 *Message:* ${message}`;
    const whatsappLink = `https://wa.me/+96892681010?text=${whatsappMessage}`;

    window.open(whatsappLink, "_blank");
  };

  // Handle Email Click
  const handleEmail = (event) => {
    event.preventDefault();

    const name = document.getElementById("name").value.trim();
    const email = document.getElementById("email").value.trim();
    const phone = document.getElementById("phone").value.trim();
    const message = document.getElementById("message").value.trim();

    if (!name || !email || !phone || !message) {
      alert("Please fill in all fields before sending.");
      return;
    }

    const subject = "New Quote Request";
    const body = `Name: ${name}%0AEmail: ${email}%0APhone: ${phone}%0AMessage: ${message}`;
    const mailtoLink = `mailto:sales@nizwaeng.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  return (
    <div className="transportation-container">
      <h1 className="transportation-title">Comprehensive Transport & Logistics Solutions for Crusher Operations</h1>
      <p className="transportation-description">
        Optimize your material movement and logistics at crusher sites with our specialized rental and transportation services. 
        We provide a robust fleet of heavy-duty vehicles and equipment, tailored to the demanding needs of aggregate production.
      </p>

      <h2 className="transportation-subtitle">Our Rental & Transportation Services Include:</h2>
      <ul className="transportation-list">
        <li><strong>Tipper & Tipper Trailer (3-Axle) Rentals:</strong> Efficiently transport aggregate materials from the crusher to stockpiles or project sites.</li>
        <li><strong>Cement Bulker (3-Axle) Rentals:</strong> Seamless cement transportation for concrete production.</li>
        <li><strong>King Low Bed (4-Axle) Rentals:</strong> Heavy-duty low bed trailers for moving machinery.</li>
        <li><strong>UD Truck Rentals:</strong> Reliable trucks for crusher operations.</li>
        <li><strong>Diesel Tanker Rentals:</strong> Ensure a steady fuel supply for equipment.</li>
        <li><strong>Water Tanker Rentals:</strong> Manage dust suppression and water supply efficiently.</li>
      </ul>

      <p className="transportation-footer">
        We understand the critical role of efficient logistics in crusher operations. Our well-maintained fleet and experienced drivers ensure timely and reliable transportation, enabling you to maintain optimal productivity.
      </p>

      <div className="quote-button-container">
        <button className="quote-button" onClick={handleShow}>
          Get a Quote
        </button>
      </div>

      {showQuoteModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={handleClose}>&times;</span>
            <h2>Get a Quote</h2>
            <form>
              <label htmlFor="name">Name</label>
              <input type="text" id="name" placeholder="Enter your name" required />

              <label htmlFor="email">Email Address</label>
              <input type="email" id="email" placeholder="Enter email" required />

              <label htmlFor="phone">Phone Number</label>
              <input type="tel" id="phone" placeholder="Enter phone number" required />

              <label htmlFor="message">Message</label>
              <textarea id="message" rows="3" placeholder="Enter your message" required></textarea>

              <div className="button-group">
                <button type="button" onClick={handleWhatsApp} className="whatsapp-button">
                  Send via WhatsApp
                </button>
                <button type="button" onClick={handleEmail} className="email-button">
                  Send via Email
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default EquipmentTransportation;
