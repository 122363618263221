import React from "react";
import "./Issa.css";
import frontImage from "../front.png"; 
import akhilImage from "./issa .jpg";
import whatsappIcon from "../../image/watsapp.jpg";
import contactIcon from "../../image/contact.png";


const BusinessCard = () => {
  const formData = {
    name: 'ISSA YAHYA AL AMRI',
    phone: '+968 99261830',
    email: 'issa@alrumeli.com',
    company: 'Nizwa Engineering',
    website1: 'https://nizwaeng.com',
    website2: 'https://alrumeli.com',
  };

  const handleWhatsApp = () => {
    const whatsappUrl = `https://wa.me/${formData.phone.replace(/\D/g, "")}`;
    window.open(whatsappUrl, "_blank");
  };


  

  const handleAddToContacts = () => {
    const vCard = `BEGIN:VCARD
VERSION:3.0
N: AL AMRI;ISSA YAHYA.
FN:${formData.name}
TEL:${formData.phone}
EMAIL:${formData.email}
ORG:Nizwa Engineering
URL:${formData.website1}
URL:${formData.website2}
ADR:${formData.address1}
ADR:${formData.address2}
END:VCARD`;

    const blob = new Blob([vCard], { type: "text/vcard" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Issa.vcf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="business-card-container">
      {/* Top Side - Business Details */}
      <div className="card-side top">
        <img src={akhilImage} alt="Akhil Details" className="akhil-image" />
        
        {/* Clickable Sections */}
        <div className="clickable-section phone">
          <button onClick={handleWhatsApp} className="action-button1">
            <img src={whatsappIcon} alt="WhatsApp" />
          </button>
          <button onClick={handleAddToContacts} className="action-button2">
            <img src={contactIcon} alt="Add to Contacts" />
          </button>
        </div>

        <a href={`mailto:${formData.email}`} className="clickable-section email">  {formData.email}
        </a>

        <a href={formData.website1} target="_blank" rel="noopener noreferrer" className="clickable-section websiteNE">
          {formData.website1.replace(/https?:\/\//, "")}
        </a>
        <a href={formData.website2} target="_blank" rel="noopener noreferrer" className="clickable-section websiteRCP">
          {formData.website2.replace(/https?:\/\//, "")}
        </a>
      </div>

      {/* Bottom Side - Back of the Business Card */}
      <div className="card-side bottom">
        <img src={frontImage} alt="Business Card Back" className="front-image" />
      </div>
    </div>
  );
};

export default BusinessCard;
