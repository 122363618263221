import React from 'react';
import { Link } from 'react-router-dom';
import './Services.css';

// Import images
import img from '../../image/Stationary_Impactor.png';
import img4 from '../../image/Stationary_Jaw.png';
import img5 from '../../image/Mobile_Impactor.png';
import img6 from '../../image/Mobile_Jaw.png';
import img7 from '../../image/Dozer.png';
import img8 from '../../image/dumper745.png';
import imgDump from '../../image/345gc.png';
import imgExc from '../../image/966_WHeelLoader.png';
import imgTipper from '../../image/KingLowbed.png';
import img3Axle from '../../image/Tipper.png';
import imgKingLowbed from '../../image/8835.png';
import imgBulker from '../../image/6564.png';
import img1 from '../../image/8H1.png';
import img2 from '../../image/8S1.png';
import img3 from '../../image/4H1.png';
import img4p from '../../image/6H1.png';

function Services() {
  // Define service sections
  const sections = [
    {
      title: 'Crushing & Screening',
      link: '/services/crusher-rentals',
      images: [img, img4, img5, img6],
    },
    {
      title: 'Heavy Equipment Rentals',
      link: '/services/cement-bulker',
      images: [img7, img8, imgDump, imgExc],
    },
    {
      title: 'Transport & Logistics Solutions',
      link: '/services/equipment-transportation',
      images: [imgTipper, img3Axle, imgKingLowbed, imgBulker],
    },
    {
      title: 'Cement Products',
      link: '/services/rcp-services',
      images: [img1, img2, img3, img4p],
    },
  ];

  return (
    <div className="main">
      <div className="services-main">
        {sections.map((section, index) => (
          <div key={index} className="service-item">
            <Link to={section.link} className="service-title-link">
              <h3 className="service-title">{section.title}</h3>
            </Link>
            <div className={`service-images ${section.title.toLowerCase().replace(/\s/g, '-')}`}>
              {section.images.map((image, idx) => (
                <div key={idx} className="service-image-item">
                  <img src={image} alt={`Service ${idx + 1}`} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
